<template>
  <div>loading</div>
</template>

<script>
export default {
  created() {
    this.checkPayment(this.$route.query.order_id)
  },
  methods: {
    checkPayment(id) {
      this.$store.dispatch('payments/checkPayment', id).then(
        () => {
          this.$swal({
            icon: 'success',
            title: 'Success!',
            text: 'Pembayaran anda telah selesai!',
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'button is-success',
            },
          })
          this.$router.push({
            name: 'dashboard-package',
          })
        },
        () => {
          this.$swal({
            icon: 'error',
            title: 'Warning!',
            text: 'Pembayaran anda belum selesai!',
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'button is-success',
            },
          })
          this.$router.push({
            name: 'dashboard-package',
          })
        }
      )
    },
  },
}
</script>
